/* eslint-disable */
import React from 'react';

const NetworkDiagramIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path d="M22.7528 33.236L22.5206 31.6114C27.7332 30.868 31.7187 26.2197 31.7187 20.9454C31.7187 15.671 27.7332 11.1322 22.5206 10.3888L22.7528 8.76416C28.7682 9.62292 33.3594 14.8595 33.3594 20.9454C33.3594 27.0313 28.7682 32.3772 22.7528 33.236Z" fill="#465A61" />
    <path d="M19.2472 33.236C13.2318 32.3772 8.64062 27.0313 8.64062 20.9454C8.64062 14.8595 13.2318 9.62292 19.2472 8.76416L19.4794 10.3888C14.2668 11.1322 10.2812 15.671 10.2812 20.9454C10.2812 26.2197 14.2668 30.868 19.4794 31.6114L19.2472 33.236Z" fill="#596C76" />
    <path d="M11.1016 31.7188H19.3594V33.3594H11.1016V31.7188Z" fill="#596C76" />
    <path d="M22.6406 31.7188H30.8984V33.3594H22.6406V31.7188Z" fill="#465A61" />
    <path d="M11.1016 8.64062H19.3594V10.2812H11.1016V8.64062Z" fill="#596C76" />
    <path d="M22.6406 8.64062H30.8984V10.2812H22.6406V8.64062Z" fill="#465A61" />
    <path d="M28.4375 16.8438L24.6914 17.2539L21 20.9453L19.3594 24.5923L21 29.2578L28.4375 25.1562V16.8438Z" fill="#FF641A" />
    <path d="M13.5625 16.8438L17.8702 17.5658L21 20.9453V29.2578L13.5625 25.1562V16.8438Z" fill="#FFD400" />
    <path d="M28.4375 16.8438L21 20.9453L13.5625 16.8438L21 12.7422L28.4375 16.8438Z" fill="#FF9F04" />
    <path d="M28.4375 16.8438L21 20.9453V12.7422L28.4375 16.8438Z" fill="#FF9F04" />
    <path d="M21 7C19.6382 7 18.5391 8.09909 18.5391 9.46094C18.5391 10.8228 19.6382 11.9219 21 11.9219C22.3618 11.9219 23.4609 10.8228 23.4609 9.46094C23.4609 8.09909 22.3618 7 21 7Z" fill="#FFDA2D" />
    <path d="M21 30.0781C19.6382 30.0781 18.5391 31.1772 18.5391 32.5391C18.5391 33.9009 19.6382 35 21 35C22.3618 35 23.4609 33.9009 23.4609 32.5391C23.4609 31.1772 22.3618 30.0781 21 30.0781Z" fill="#FFDA2D" />
    <path d="M35 35H30.0781V30.0781H35V35Z" fill="#FDBF00" />
    <path d="M11.9219 35H7V30.0781H11.9219V35Z" fill="#FFDA2D" />
    <path d="M11.9219 11.9219H7V7H11.9219V11.9219Z" fill="#FFDA2D" />
    <path d="M35 11.9219H30.0781V7H35V11.9219Z" fill="#FDBF00" />
    <path d="M23.4609 32.5391C23.4609 33.9009 22.3618 35 21 35V30.0781C22.3618 30.0781 23.4609 31.1772 23.4609 32.5391Z" fill="#FDBF00" />
    <path d="M21 11.9219V7C22.3618 7 23.4609 8.09909 23.4609 9.46094C23.4609 10.8228 22.3618 11.9219 21 11.9219Z" fill="#FDBF00" />
  </svg>
);

export default NetworkDiagramIcon;
/* eslint-enable */
