import React from 'react';

const AppDrawerIcon = props => (
  <svg
    viewBox="0 0 21 21"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7h5V2H2v5zm0 6h5V8H2v5zm6 0h5V8H8v5zm6 0h5V8h-5v5zM2 19h5v-5H2v5zm6 0h5v-5H8v5zm6 0h5v-5h-5v5zM8 7h5V2H8v5zm6-5v5h5V2h-5z"
      fill="currentColor"
    /></svg>
);

export default AppDrawerIcon;
