/* eslint-disable */
import React from 'react';

const UsersIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path d="M8 6.23077C8 5.55104 8.55103 5 9.23077 5H32.1231C32.8028 5 33.3538 5.55103 33.3538 6.23077V35.7692C33.3538 36.449 32.8028 37 32.1231 37H9.23077C8.55103 37 8 36.449 8 35.7692V6.23077Z" fill="#F7F7F7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.6769 10.1689C13.2209 10.1689 13.6615 9.72832 13.6615 9.18432C13.6615 8.64032 13.2209 8.19971 12.6769 8.19971C12.1329 8.19971 11.6923 8.64032 11.6923 9.18432C11.6923 9.72832 12.1329 10.1689 12.6769 10.1689V10.1689ZM12.6769 10.6612C12.0196 10.6612 10.7076 10.9911 10.7076 11.6459V12.1382H14.6461V11.6459C14.6461 10.9911 13.3341 10.6612 12.6769 10.6612V10.6612Z" fill="#B3B3B3" />
    <path d="M16.8615 8.81558C16.8615 8.47571 17.137 8.2002 17.4769 8.2002H21.563C21.9029 8.2002 22.1784 8.47571 22.1784 8.81558V8.81558C22.1784 9.15545 21.9029 9.43096 21.563 9.43096H17.4769C17.137 9.43096 16.8615 9.15545 16.8615 8.81558V8.81558Z" fill="#E4E4E4" />
    <path d="M16.8615 10.5387C16.8615 10.3347 17.0268 10.1694 17.2307 10.1694H26.0923C26.2962 10.1694 26.4615 10.3347 26.4615 10.5387V10.5387C26.4615 10.7426 26.2962 10.9079 26.0923 10.9079H17.2307C17.0268 10.9079 16.8615 10.7426 16.8615 10.5387V10.5387Z" fill="#E4E4E4" />
    <path d="M16.8615 11.7696C16.8615 11.5657 17.0268 11.4004 17.2307 11.4004H28.5538C28.7577 11.4004 28.923 11.5657 28.923 11.7696V11.7696C28.923 11.9735 28.7577 12.1389 28.5538 12.1389H17.2307C17.0268 12.1389 16.8615 11.9735 16.8615 11.7696V11.7696Z" fill="#E4E4E4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.6769 17.3076C13.2209 17.3076 13.6615 16.867 13.6615 16.323C13.6615 15.779 13.2209 15.3384 12.6769 15.3384C12.1329 15.3384 11.6923 15.779 11.6923 16.323C11.6923 16.867 12.1329 17.3076 12.6769 17.3076V17.3076ZM12.6769 17.7999C12.0196 17.7999 10.7076 18.1298 10.7076 18.7845V19.2768H14.6461V18.7845C14.6461 18.1298 13.3341 17.7999 12.6769 17.7999V17.7999Z" fill="#B3B3B3" />
    <path d="M16.8615 15.9538C16.8615 15.6139 17.137 15.3384 17.4769 15.3384H22.8923C23.2321 15.3384 23.5077 15.6139 23.5077 15.9538V15.9538C23.5077 16.2936 23.2321 16.5691 22.8923 16.5691H17.4769C17.137 16.5691 16.8615 16.2936 16.8615 15.9538V15.9538Z" fill="#E4E4E4" />
    <path d="M16.8615 17.6768C16.8615 17.4729 17.0268 17.3076 17.2307 17.3076H28.5538C28.7577 17.3076 28.923 17.4729 28.923 17.6768V17.6768C28.923 17.8808 28.7577 18.0461 28.5538 18.0461H17.2307C17.0268 18.0461 16.8615 17.8808 16.8615 17.6768V17.6768Z" fill="#E4E4E4" />
    <path d="M16.8615 18.9078C16.8615 18.7039 17.0268 18.5386 17.2307 18.5386H26.0923C26.2962 18.5386 26.4615 18.7039 26.4615 18.9078V18.9078C26.4615 19.1117 26.2962 19.277 26.0923 19.277H17.2307C17.0268 19.277 16.8615 19.1117 16.8615 18.9078V18.9078Z" fill="#E4E4E4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.6769 24.4458C13.2209 24.4458 13.6615 24.0052 13.6615 23.4612C13.6615 22.9172 13.2209 22.4766 12.6769 22.4766C12.1329 22.4766 11.6923 22.9172 11.6923 23.4612C11.6923 24.0052 12.1329 24.4458 12.6769 24.4458V24.4458ZM12.6769 24.9381C12.0196 24.9381 10.7076 25.2679 10.7076 25.9227V26.415H14.6461V25.9227C14.6461 25.2679 13.3341 24.9381 12.6769 24.9381V24.9381Z" fill="#B3B3B3" />
    <path d="M16.8615 23.0919C16.8615 22.7521 17.137 22.4766 17.4769 22.4766H20.4307C20.7706 22.4766 21.0461 22.7521 21.0461 23.0919V23.0919C21.0461 23.4318 20.7706 23.7073 20.4307 23.7073H17.4769C17.137 23.7073 16.8615 23.4318 16.8615 23.0919V23.0919Z" fill="#E4E4E4" />
    <path d="M16.8615 24.815C16.8615 24.6111 17.0268 24.4458 17.2307 24.4458H29.7846C29.9885 24.4458 30.1538 24.6111 30.1538 24.815V24.815C30.1538 25.019 29.9885 25.1843 29.7846 25.1843H17.2307C17.0268 25.1843 16.8615 25.019 16.8615 24.815V24.815Z" fill="#E4E4E4" />
    <path d="M16.8615 26.0455C16.8615 25.8416 17.0268 25.6763 17.2307 25.6763H28.5538C28.7577 25.6763 28.923 25.8416 28.923 26.0455V26.0455C28.923 26.2494 28.7577 26.4147 28.5538 26.4147H17.2307C17.0268 26.4147 16.8615 26.2494 16.8615 26.0455V26.0455Z" fill="#E4E4E4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.6769 31.5845C13.2209 31.5845 13.6615 31.1438 13.6615 30.5998C13.6615 30.0558 13.2209 29.6152 12.6769 29.6152C12.1329 29.6152 11.6923 30.0558 11.6923 30.5998C11.6923 31.1438 12.1329 31.5845 12.6769 31.5845V31.5845ZM12.6769 32.0768C12.0196 32.0768 10.7076 32.4066 10.7076 33.0614V33.5537H14.6461V33.0614C14.6461 32.4066 13.3341 32.0768 12.6769 32.0768V32.0768Z" fill="#B3B3B3" />
    <path d="M16.8615 30.2306C16.8615 29.8908 17.137 29.6152 17.4769 29.6152H24.8615C25.2014 29.6152 25.4769 29.8908 25.4769 30.2306V30.2306C25.4769 30.5705 25.2014 30.846 24.8615 30.846H17.4769C17.137 30.846 16.8615 30.5705 16.8615 30.2306V30.2306Z" fill="#E4E4E4" />
    <path d="M16.8615 31.9537C16.8615 31.7498 17.0268 31.5845 17.2307 31.5845H26.0923C26.2962 31.5845 26.4615 31.7498 26.4615 31.9537V31.9537C26.4615 32.1576 26.2962 32.3229 26.0923 32.3229H17.2307C17.0268 32.3229 16.8615 32.1576 16.8615 31.9537V31.9537Z" fill="#E4E4E4" />
    <path d="M16.8615 33.1847C16.8615 32.9807 17.0268 32.8154 17.2307 32.8154H28.5538C28.7577 32.8154 28.923 32.9807 28.923 33.1847V33.1847C28.923 33.3886 28.7577 33.5539 28.5538 33.5539H17.2307C17.0268 33.5539 16.8615 33.3886 16.8615 33.1847V33.1847Z" fill="#E4E4E4" />
  </svg>
);

export default UsersIcon;
/* eslint-enable */
