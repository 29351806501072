/* eslint-disable */
import React from 'react';

const SchedulerIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path d="M37.7586 35.2758H4.24137C3.55575 35.2758 3 34.72 3 34.0344V5.4827C3 4.79708 3.55575 4.24133 4.24137 4.24133H37.7586C38.4443 4.24133 39 4.79708 39 5.4827V34.0344C39 34.72 38.4442 35.2758 37.7586 35.2758Z" fill="#EFF2FA" />
    <path d="M29.5909 35.2761C30.4378 33.8142 30.9304 32.1216 30.9304 30.3105C30.9304 24.8258 26.4841 20.3795 20.9994 20.3795C15.5146 20.3795 11.0684 24.8258 11.0684 30.3105C11.0684 32.1216 11.561 33.8141 12.4078 35.2761H29.5909Z" fill="#E4EAF6" />
    <path d="M39 10.4482H3V5.4827C3 4.79708 3.55575 4.24133 4.24137 4.24133H37.7586C38.4443 4.24133 39 4.79708 39 5.4827V10.4482Z" fill="#FF6464" />
    <path d="M39 10.4483H3V11.6897H39V10.4483Z" fill="#E4EAF6" />
    <path d="M8.27645 8.89696C9.30485 8.89696 10.1385 8.06328 10.1385 7.03488C10.1385 6.00648 9.30485 5.17279 8.27645 5.17279C7.24805 5.17279 6.41437 6.00648 6.41437 7.03488C6.41437 8.06328 7.24805 8.89696 8.27645 8.89696Z" fill="#D2555A" />
    <path d="M8.27616 7.96554C7.76197 7.96554 7.34515 7.54866 7.34515 7.03453V3.93101C7.34515 3.41688 7.76204 3 8.27616 3C8.79036 3 9.20717 3.41688 9.20717 3.93101V7.03446C9.20724 7.54866 8.79036 7.96554 8.27616 7.96554Z" fill="#C7CFE2" />
    <path d="M8.27616 6.1038C7.76197 6.1038 7.34515 5.68692 7.34515 5.17279V7.03488C7.34515 7.54907 7.76204 7.96588 8.27616 7.96588C8.79036 7.96588 9.20717 7.549 9.20717 7.03488V5.17279C9.20724 5.68692 8.79036 6.1038 8.27616 6.1038Z" fill="#AFB9D2" />
    <path d="M33.7245 8.89696C34.7529 8.89696 35.5866 8.06328 35.5866 7.03488C35.5866 6.00648 34.7529 5.17279 33.7245 5.17279C32.6961 5.17279 31.8624 6.00648 31.8624 7.03488C31.8624 8.06328 32.6961 8.89696 33.7245 8.89696Z" fill="#D2555A" />
    <path d="M33.7234 7.96554C33.2092 7.96554 32.7924 7.54866 32.7924 7.03453V3.93101C32.7923 3.41688 33.2092 3 33.7234 3C34.2376 3 34.6544 3.41688 34.6544 3.93101V7.03446C34.6544 7.54866 34.2376 7.96554 33.7234 7.96554Z" fill="#C7CFE2" />
    <path d="M33.7234 6.1038C33.2092 6.1038 32.7924 5.68692 32.7924 5.17279V7.03488C32.7924 7.54907 33.2093 7.96588 33.7234 7.96588C34.2376 7.96588 34.6544 7.549 34.6544 7.03488V5.17279C34.6544 5.68692 34.2376 6.1038 33.7234 6.1038Z" fill="#AFB9D2" />
    <path d="M9.82751 19.138H7.34478C7.00201 19.138 6.72406 18.8601 6.72406 18.5173V16.6552C6.72406 16.3124 7.00201 16.0345 7.34478 16.0345H9.82751C10.1703 16.0345 10.4482 16.3124 10.4482 16.6552V18.5173C10.4482 18.8601 10.1703 19.138 9.82751 19.138Z" fill="#E4EAF6" />
    <path d="M14.7933 19.1379H12.3106C11.9678 19.1379 11.6899 18.8599 11.6899 18.5172V16.6551C11.6899 16.3123 11.9678 16.0344 12.3106 16.0344H14.7933C15.1361 16.0344 15.4141 16.3123 15.4141 16.6551V18.5172C15.4141 18.86 15.1361 19.1379 14.7933 19.1379Z" fill="#E4EAF6" />
    <path d="M19.7587 19.1379H17.276C16.9332 19.1379 16.6553 18.8599 16.6553 18.5172V16.6551C16.6553 16.3123 16.9332 16.0344 17.276 16.0344H19.7587C20.1015 16.0344 20.3794 16.3123 20.3794 16.6551V18.5172C20.3794 18.86 20.1015 19.1379 19.7587 19.1379Z" fill="#E4EAF6" />
    <path d="M24.7241 19.1379H22.2414C21.8986 19.1379 21.6207 18.8599 21.6207 18.5172V16.6551C21.6207 16.3123 21.8986 16.0344 22.2414 16.0344H24.7241C25.0669 16.0344 25.3448 16.3123 25.3448 16.6551V18.5172C25.3448 18.86 25.0669 19.1379 24.7241 19.1379Z" fill="#E4EAF6" />
    <path d="M29.6899 19.138H27.2072C26.8644 19.138 26.5865 18.8601 26.5865 18.5173V16.6552C26.5865 16.3124 26.8644 16.0345 27.2072 16.0345H29.6899C30.0327 16.0345 30.3107 16.3124 30.3107 16.6552V18.5173C30.3107 18.8601 30.0327 19.138 29.6899 19.138Z" fill="#D7DEED" />
    <path d="M34.6541 19.1379H32.1714C31.8286 19.1379 31.5507 18.8599 31.5507 18.5172V16.6551C31.5507 16.3123 31.8286 16.0344 32.1714 16.0344H34.6541C34.9969 16.0344 35.2748 16.3123 35.2748 16.6551V18.5172C35.2748 18.86 34.9969 19.1379 34.6541 19.1379Z" fill="#D7DEED" />
    <path d="M9.82751 14.7936H7.34478C7.00201 14.7936 6.72406 14.5156 6.72406 14.1728C6.72406 13.8301 7.00201 13.5521 7.34478 13.5521H9.82751C10.1703 13.5521 10.4482 13.8301 10.4482 14.1728C10.4482 14.5157 10.1703 14.7936 9.82751 14.7936Z" fill="#C7CFE2" />
    <path d="M14.7933 14.7931H12.3106C11.9678 14.7931 11.6899 14.5151 11.6899 14.1724C11.6899 13.8296 11.9678 13.5516 12.3106 13.5516H14.7933C15.1361 13.5516 15.4141 13.8296 15.4141 14.1724C15.4141 14.5152 15.1361 14.7931 14.7933 14.7931Z" fill="#C7CFE2" />
    <path d="M19.7587 14.7931H17.276C16.9332 14.7931 16.6553 14.5151 16.6553 14.1724C16.6553 13.8296 16.9332 13.5516 17.276 13.5516H19.7587C20.1015 13.5516 20.3794 13.8296 20.3794 14.1724C20.3794 14.5152 20.1015 14.7931 19.7587 14.7931Z" fill="#C7CFE2" />
    <path d="M24.7241 14.7931H22.2414C21.8986 14.7931 21.6207 14.5151 21.6207 14.1724C21.6207 13.8296 21.8986 13.5516 22.2414 13.5516H24.7241C25.0669 13.5516 25.3448 13.8296 25.3448 14.1724C25.3448 14.5152 25.0669 14.7931 24.7241 14.7931Z" fill="#C7CFE2" />
    <path d="M29.6899 14.7936H27.2072C26.8644 14.7936 26.5865 14.5156 26.5865 14.1728C26.5865 13.8301 26.8644 13.5521 27.2072 13.5521H29.6899C30.0327 13.5521 30.3107 13.8301 30.3107 14.1728C30.3107 14.5157 30.0327 14.7936 29.6899 14.7936Z" fill="#FF6464" />
    <path d="M34.6541 14.7931H32.1714C31.8286 14.7931 31.5507 14.5151 31.5507 14.1724C31.5507 13.8296 31.8286 13.5516 32.1714 13.5516H34.6541C34.9969 13.5516 35.2748 13.8296 35.2748 14.1724C35.2748 14.5152 34.9969 14.7931 34.6541 14.7931Z" fill="#FF6464" />
    <path d="M9.82751 23.4836H7.34478C7.00201 23.4836 6.72406 23.2056 6.72406 22.8629V21.0008C6.72406 20.6581 7.00201 20.3801 7.34478 20.3801H9.82751C10.1703 20.3801 10.4482 20.6581 10.4482 21.0008V22.8629C10.4482 23.2057 10.1703 23.4836 9.82751 23.4836Z" fill="#E4EAF6" />
    <path d="M14.7933 23.4829H12.3106C11.9678 23.4829 11.6899 23.205 11.6899 22.8622V21.0002C11.6899 20.6574 11.9678 20.3795 12.3106 20.3795H14.7933C15.1361 20.3795 15.4141 20.6574 15.4141 21.0002V22.8623C15.4141 23.205 15.1361 23.4829 14.7933 23.4829Z" fill="#E4EAF6" />
    <path d="M19.7587 23.4829H17.276C16.9332 23.4829 16.6553 23.205 16.6553 22.8622V21.0002C16.6553 20.6574 16.9332 20.3795 17.276 20.3795H19.7587C20.1015 20.3795 20.3794 20.6574 20.3794 21.0002V22.8623C20.3794 23.205 20.1015 23.4829 19.7587 23.4829Z" fill="#E4EAF6" />
    <path d="M24.7241 23.4829H22.2414C21.8986 23.4829 21.6207 23.205 21.6207 22.8622V21.0002C21.6207 20.6574 21.8986 20.3795 22.2414 20.3795H24.7241C25.0669 20.3795 25.3448 20.6574 25.3448 21.0002V22.8623C25.3448 23.205 25.0669 23.4829 24.7241 23.4829Z" fill="#E4EAF6" />
    <path d="M29.6899 23.4836H27.2072C26.8644 23.4836 26.5865 23.2056 26.5865 22.8629V21.0008C26.5865 20.6581 26.8644 20.3801 27.2072 20.3801H29.6899C30.0327 20.3801 30.3107 20.6581 30.3107 21.0008V22.8629C30.3107 23.2057 30.0327 23.4836 29.6899 23.4836Z" fill="#D7DEED" />
    <path d="M34.6541 23.4829H32.1714C31.8286 23.4829 31.5507 23.205 31.5507 22.8622V21.0002C31.5507 20.6574 31.8286 20.3795 32.1714 20.3795H34.6541C34.9969 20.3795 35.2748 20.6574 35.2748 21.0002V22.8623C35.2748 23.205 34.9969 23.4829 34.6541 23.4829Z" fill="#D7DEED" />
    <path d="M9.82751 27.828H7.34478C7.00201 27.828 6.72406 27.5501 6.72406 27.2073V25.3452C6.72406 25.0024 7.00201 24.7245 7.34478 24.7245H9.82751C10.1703 24.7245 10.4482 25.0024 10.4482 25.3452V27.2073C10.4482 27.5501 10.1703 27.828 9.82751 27.828Z" fill="#E4EAF6" />
    <path d="M14.7933 27.8275H12.3106C11.9678 27.8275 11.6899 27.5496 11.6899 27.2068V25.3447C11.6899 25.0019 11.9678 24.724 12.3106 24.724H14.7933C15.1361 24.724 15.4141 25.0019 15.4141 25.3447V27.2068C15.4141 27.5496 15.1361 27.8275 14.7933 27.8275Z" fill="#E4EAF6" />
    <path d="M19.7587 27.8275H17.276C16.9332 27.8275 16.6553 27.5496 16.6553 27.2068V25.3447C16.6553 25.0019 16.9332 24.724 17.276 24.724H19.7587C20.1015 24.724 20.3794 25.0019 20.3794 25.3447V27.2068C20.3794 27.5496 20.1015 27.8275 19.7587 27.8275Z" fill="#E4EAF6" />
    <path d="M24.7241 27.8275H22.2414C21.8986 27.8275 21.6207 27.5496 21.6207 27.2068V25.3447C21.6207 25.0019 21.8986 24.724 22.2414 24.724H24.7241C25.0669 24.724 25.3448 25.0019 25.3448 25.3447V27.2068C25.3448 27.5496 25.0669 27.8275 24.7241 27.8275Z" fill="#E4EAF6" />
    <path d="M29.6899 27.828H27.2072C26.8644 27.828 26.5865 27.5501 26.5865 27.2073V25.3452C26.5865 25.0024 26.8644 24.7245 27.2072 24.7245H29.6899C30.0327 24.7245 30.3107 25.0024 30.3107 25.3452V27.2073C30.3107 27.5501 30.0327 27.828 29.6899 27.828Z" fill="#D7DEED" />
    <path d="M34.6541 27.8275H32.1714C31.8286 27.8275 31.5507 27.5496 31.5507 27.2068V25.3447C31.5507 25.0019 31.8286 24.724 32.1714 24.724H34.6541C34.9969 24.724 35.2748 25.0019 35.2748 25.3447V27.2068C35.2748 27.5496 34.9969 27.8275 34.6541 27.8275Z" fill="#D7DEED" />
    <path d="M9.82751 32.1727H7.34478C7.00201 32.1727 6.72406 31.8947 6.72406 31.552V29.6899C6.72406 29.3471 7.00201 29.0692 7.34478 29.0692H9.82751C10.1703 29.0692 10.4482 29.3471 10.4482 29.6899V31.552C10.4482 31.8947 10.1703 32.1727 9.82751 32.1727Z" fill="#E4EAF6" />
    <path d="M14.7933 32.1724H12.3106C11.9678 32.1724 11.6899 31.8945 11.6899 31.5517V29.6896C11.6899 29.3469 11.9678 29.0689 12.3106 29.0689H14.7933C15.1361 29.0689 15.4141 29.3469 15.4141 29.6896V31.5517C15.4141 31.8945 15.1361 32.1724 14.7933 32.1724Z" fill="#E4EAF6" />
    <path d="M19.7587 32.1724H17.276C16.9332 32.1724 16.6553 31.8945 16.6553 31.5517V29.6896C16.6553 29.3469 16.9332 29.0689 17.276 29.0689H19.7587C20.1015 29.0689 20.3794 29.3469 20.3794 29.6896V31.5517C20.3794 31.8945 20.1015 32.1724 19.7587 32.1724Z" fill="#E4EAF6" />
    <path d="M24.7241 32.1724H22.2414C21.8986 32.1724 21.6207 31.8945 21.6207 31.5517V29.6896C21.6207 29.3469 21.8986 29.0689 22.2414 29.0689H24.7241C25.0669 29.0689 25.3448 29.3469 25.3448 29.6896V31.5517C25.3448 31.8945 25.0669 32.1724 24.7241 32.1724Z" fill="#E4EAF6" />
    <path d="M29.6899 32.1727H27.2072C26.8644 32.1727 26.5865 31.8947 26.5865 31.552V29.6899C26.5865 29.3471 26.8644 29.0692 27.2072 29.0692H29.6899C30.0327 29.0692 30.3107 29.3471 30.3107 29.6899V31.552C30.3107 31.8947 30.0327 32.1727 29.6899 32.1727Z" fill="#D7DEED" />
    <path d="M34.6541 32.1724H32.1714C31.8286 32.1724 31.5507 31.8945 31.5507 31.5517V29.6896C31.5507 29.3469 31.8286 29.0689 32.1714 29.0689H34.6541C34.9969 29.0689 35.2748 29.3469 35.2748 29.6896V31.5517C35.2748 31.8945 34.9969 32.1724 34.6541 32.1724Z" fill="#D7DEED" />
    <path d="M21.0002 38.9999C25.7993 38.9999 29.6898 35.1095 29.6898 30.3103C29.6898 25.5112 25.7993 21.6207 21.0002 21.6207C16.201 21.6207 12.3105 25.5112 12.3105 30.3103C12.3105 35.1095 16.201 38.9999 21.0002 38.9999Z" fill="#AFB9D2" />
    <path d="M21.0002 37.7588C25.1137 37.7588 28.4484 34.4241 28.4484 30.3105C28.4484 26.1969 25.1137 22.8622 21.0002 22.8622C16.8866 22.8622 13.5519 26.1969 13.5519 30.3105C13.5519 34.4241 16.8866 37.7588 21.0002 37.7588Z" fill="white" />
    <path d="M21.0005 34.6553C23.4001 34.6553 25.3453 32.71 25.3453 30.3105C25.3453 27.9109 23.4001 25.9656 21.0005 25.9656C18.6009 25.9656 16.6557 27.9109 16.6557 30.3105C16.6557 32.71 18.6009 34.6553 21.0005 34.6553Z" fill="#EFF2FA" />
    <path d="M20.9998 22.2416C20.657 22.2416 20.3791 22.5196 20.3791 22.8624V23.4831C20.3791 23.8258 20.657 24.1038 20.9998 24.1038C21.3426 24.1038 21.6205 23.8258 21.6205 23.4831V22.8624C21.6205 22.5196 21.3426 22.2416 20.9998 22.2416Z" fill="#707487" />
    <path d="M20.9998 36.5176C20.657 36.5176 20.3791 36.7955 20.3791 37.1383V37.759C20.3791 38.1018 20.657 38.3797 20.9998 38.3797C21.3426 38.3797 21.6205 38.1018 21.6205 37.759V37.1383C21.6205 36.7955 21.3426 36.5176 20.9998 36.5176Z" fill="#707487" />
    <path d="M29.0691 30.3107C29.0691 29.9679 28.7911 29.6899 28.4483 29.6899H27.8276C27.4849 29.6899 27.2069 29.9679 27.2069 30.3107C27.2069 30.6534 27.4849 30.9314 27.8276 30.9314H28.4483C28.7911 30.9313 29.0691 30.6534 29.0691 30.3107Z" fill="#707487" />
    <path d="M14.7931 30.3107C14.7931 29.9679 14.5152 29.6899 14.1724 29.6899H13.5517C13.2089 29.6899 12.931 29.9679 12.931 30.3107C12.931 30.6534 13.2089 30.9314 13.5517 30.9314H14.1724C14.5153 30.9313 14.7931 30.6534 14.7931 30.3107Z" fill="#707487" />
    <path d="M20.9994 32.7931C20.6566 32.7931 20.3787 32.5151 20.3787 32.1724V25.9654C20.3787 25.6227 20.6566 25.3447 20.9994 25.3447C21.3422 25.3447 21.6201 25.6227 21.6201 25.9654V32.1724C21.6201 32.5152 21.3422 32.7931 20.9994 32.7931Z" fill="#5B5D6E" />
    <path d="M18.517 30.3104C18.517 29.9676 18.795 29.6896 19.1377 29.6896H25.3447C25.6874 29.6896 25.9654 29.9676 25.9654 30.3104C25.9654 30.6531 25.6874 30.9311 25.3447 30.9311H19.1377C18.7949 30.931 18.517 30.6531 18.517 30.3104Z" fill="#5B5D6E" />
    <path d="M21 31.5517C21.6856 31.5517 22.2414 30.9959 22.2414 30.3103C22.2414 29.6247 21.6856 29.069 21 29.069C20.3144 29.069 19.7587 29.6247 19.7587 30.3103C19.7587 30.9959 20.3144 31.5517 21 31.5517Z" fill="#C7CFE2" />
  </svg>
);

export default SchedulerIcon;
/* eslint-enable */
