/* eslint-disable */
import React from 'react';

const ApiIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path d="M31.9992 7H6V26.5078H31.9992V7Z" fill="#E0E0E0" />
    <path d="M32 7H19V26.5078H32V7Z" fill="#C0C0C0" />
    <path d="M31.9992 7H6V9.51027H31.9992V7Z" fill="#C0C0C0" />
    <path d="M32 7H19V9.51027H32V7Z" fill="#929292" />
    <path d="M7.5662 8.95051C7.95499 8.95051 8.27016 8.63899 8.27016 8.2547C8.27016 7.87042 7.95499 7.5589 7.5662 7.5589C7.17742 7.5589 6.86224 7.87042 6.86224 8.2547C6.86224 8.63899 7.17742 8.95051 7.5662 8.95051Z" fill="#FF6838" />
    <path d="M9.67796 8.95051C10.0667 8.95051 10.3819 8.63899 10.3819 8.2547C10.3819 7.87042 10.0667 7.5589 9.67796 7.5589C9.28917 7.5589 8.974 7.87042 8.974 8.2547C8.974 8.63899 9.28917 8.95051 9.67796 8.95051Z" fill="#FF6838" />
    <path d="M11.7897 8.95051C12.1785 8.95051 12.4937 8.63899 12.4937 8.2547C12.4937 7.87042 12.1785 7.5589 11.7897 7.5589C11.4009 7.5589 11.0858 7.87042 11.0858 8.2547C11.0858 8.63899 11.4009 8.95051 11.7897 8.95051Z" fill="#FF6838" />
    <path d="M7.01245 15.9904L8.9658 10.9692H9.91741L11.8636 15.9904H10.6185L10.2251 14.8659H8.64378L8.25742 15.9904H7.01245ZM9.43806 12.1997L8.82986 14.0668H10.0248L9.43806 12.1997Z" fill="#005C83" />
    <path d="M12.3284 15.9904V10.9692H14.4964C14.7397 10.9692 14.9626 11.0186 15.1654 11.1177C15.3681 11.2167 15.5421 11.3464 15.6877 11.5067C15.8332 11.667 15.9477 11.8485 16.0312 12.0512C16.1147 12.254 16.1564 12.4591 16.1564 12.6665C16.1564 12.8882 16.117 13.1004 16.0384 13.303C15.9597 13.5058 15.8499 13.6873 15.7092 13.8476C15.5685 14.0079 15.398 14.1352 15.1977 14.2295C14.9973 14.3238 14.7755 14.371 14.5323 14.371H13.5019V15.9905H12.3284V15.9904ZM13.5018 13.3595H14.4607C14.5989 13.3595 14.7183 13.3006 14.8184 13.1827C14.9186 13.0649 14.9686 12.8927 14.9686 12.6664C14.9686 12.5486 14.9531 12.4472 14.9221 12.3623C14.8909 12.2774 14.8493 12.2068 14.7968 12.1502C14.7443 12.0936 14.6847 12.0524 14.6179 12.0264C14.5511 12.0005 14.4844 11.9875 14.4176 11.9875H13.5017V13.3595H13.5018Z" fill="#005C83" />
    <path d="M16.7649 15.9904V10.9692H17.9383V15.9904H16.7649Z" fill="#005C83" />
    <path d="M32.7337 21.6069L33.8202 20.5204C33.199 19.6317 32.4251 18.8578 31.5365 18.2367L30.45 19.3232C29.678 18.8228 28.8108 18.4571 27.8815 18.2587V16.7215C27.3572 16.6288 26.8176 16.5803 26.2667 16.5803C25.7158 16.5803 25.1762 16.6288 24.6519 16.7215V18.2587C23.7226 18.457 22.8554 18.8228 22.0834 19.3231L20.9969 18.2366C20.1082 18.8577 19.3343 19.6316 18.7132 20.5203L19.7997 21.6068C19.2993 22.3787 18.9336 23.246 18.7352 24.1753H17.198C17.1053 24.6996 17.0568 25.2392 17.0568 25.7901C17.0568 26.341 17.1053 26.8806 17.198 27.4049H18.7352C18.9335 28.3342 19.2993 29.2014 19.7997 29.9734L18.7132 31.0599C19.3343 31.9485 20.1082 32.7224 20.9969 33.3436L22.0834 32.2571C22.8553 32.7575 23.7226 33.1232 24.6519 33.3215V34.8587C25.1762 34.9514 25.7158 35 26.2667 35C26.8176 35 27.3572 34.9514 27.8815 34.8587V33.3215C28.8108 33.1232 29.6779 32.7575 30.45 32.2571L31.5365 33.3436C32.4251 32.7224 33.199 31.9485 33.8202 31.0599L32.7337 29.9734C33.2341 29.2014 33.5998 28.3342 33.7981 27.4049H35.3353C35.428 26.8806 35.4766 26.341 35.4766 25.7901C35.4766 25.2392 35.428 24.6996 35.3353 24.1753H33.7981C33.5998 23.2461 33.2341 22.3789 32.7337 21.6069ZM26.2667 29.1916C24.3881 29.1916 22.8653 27.6687 22.8653 25.7902C22.8653 23.9116 24.3881 22.3888 26.2667 22.3888C28.1452 22.3888 29.6681 23.9116 29.6681 25.7902C29.6681 27.6687 28.1451 29.1916 26.2667 29.1916Z" fill="#005C83" />
    <path d="M35.3351 27.4051C35.4279 26.8808 35.4764 26.3412 35.4764 25.7902C35.4764 25.2393 35.4279 24.6997 35.3351 24.1754H33.7979C33.5997 23.2461 33.2339 22.3788 32.7336 21.6069L33.8201 20.5204C33.1989 19.6317 32.425 18.8578 31.5364 18.2367L30.4499 19.3232C29.6779 18.8228 28.8107 18.457 27.8814 18.2587V16.7215C27.3571 16.6288 26.8175 16.5803 26.2666 16.5803C26.2308 16.5803 26.1955 16.5822 26.16 16.5826V22.3914C26.1954 22.3904 26.2308 22.3888 26.2666 22.3888C28.1451 22.3888 29.668 23.9116 29.668 25.7901C29.668 27.6686 28.1452 29.1915 26.2666 29.1915C26.2308 29.1915 26.1954 29.1899 26.16 29.1888V34.9977C26.1956 34.998 26.2309 35 26.2666 35C26.8176 35 27.3571 34.9514 27.8814 34.8587V33.3215C28.8107 33.1232 29.6778 32.7575 30.4499 32.2571L31.5364 33.3436C32.425 32.7224 33.1989 31.9485 33.8201 31.0598L32.7336 29.9733C33.234 29.2013 33.5997 28.3341 33.7979 27.4048H35.3351V27.4051Z" fill="#00263F" />
  </svg>

);

export default ApiIcon;
/* eslint-enable */
