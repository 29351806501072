/* eslint-disable */
import React from 'react';

const PrepaidPassManagerIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M26.6122 2.24121L26.6943 3.04529C27.0228 6.26114 27.0789 9.20882 26.256 11.2693C25.7834 12.4529 25.0261 13.6826 24.1617 14.5724C23.3229 15.4357 22.2615 16.0984 21.2069 15.8371C20.1131 15.566 19.6283 14.4645 19.5606 13.2748C19.4911 12.0552 19.8326 10.565 20.5726 9.17553C21.6889 7.07923 22.8622 5.61539 26.0085 2.78445L26.6122 2.24121ZM25.953 3.94151C23.3043 6.37686 22.2834 7.71005 21.3003 9.55619C20.6186 10.8362 20.3224 12.1799 20.3821 13.2289C20.4435 14.3077 20.8546 14.9092 21.4065 15.0459C21.9977 15.1924 22.7637 14.8356 23.5689 14.0067C24.3485 13.2043 25.0517 12.069 25.4909 10.9692C26.1683 9.27305 26.2042 6.81791 25.953 3.94151Z" fill="#EDEDED" />
    <path d="M14.1889 11.1191H19.8057C19.6052 11.8776 19.5228 12.6157 19.5603 13.2747C19.5626 13.316 19.5655 13.3572 19.5689 13.3983C18.861 13.8086 18.3853 14.5699 18.3853 15.4414C18.3853 16.7475 19.4537 17.8064 20.7716 17.8064C22.0895 17.8064 23.1578 16.7475 23.1578 15.4414C23.1578 14.1353 22.0895 13.0764 20.7716 13.0764C20.637 13.0764 20.505 13.0874 20.3766 13.1086C20.3583 12.5121 20.4509 11.8283 20.6594 11.1191H27.3543C28.2632 11.1191 29 11.8494 29 12.7502V36.6449C29 37.5457 28.2632 38.276 27.3543 38.276H14.1889C13.2799 38.276 12.5432 37.5457 12.5432 36.6449V12.7502C12.5432 11.8494 13.2799 11.1191 14.1889 11.1191Z" fill="#F86E51" />
    <path d="M17.8918 22.5362C17.8918 22.2659 18.1128 22.0469 18.3855 22.0469H23.158C23.4306 22.0469 23.6517 22.2659 23.6517 22.5362C23.6517 22.8064 23.4306 23.0255 23.158 23.0255H18.3855C18.1128 23.0255 17.8918 22.8064 17.8918 22.5362Z" fill="#565464" />
    <path d="M15.5054 24.9835C15.5054 24.7132 15.7265 24.4941 15.9991 24.4941H25.5441C25.8168 24.4941 26.0378 24.7132 26.0378 24.9835C26.0378 25.2537 25.8168 25.4728 25.5441 25.4728H15.9991C15.7265 25.4728 15.5054 25.2537 15.5054 24.9835Z" fill="#565464" />
    <path d="M13.5304 30.8551C13.5304 30.1345 14.1198 29.5503 14.847 29.5503H26.6959C27.423 29.5503 28.0124 30.1345 28.0124 30.8551V35.3405C28.0124 36.0611 27.423 36.6453 26.6959 36.6453H14.847C14.1198 36.6453 13.5304 36.0611 13.5304 35.3405V30.8551Z" fill="white" />
    <path d="M15.9168 30.773C15.9168 30.6379 16.0273 30.5283 16.1636 30.5283C16.3 30.5283 16.4105 30.6379 16.4105 30.773V34.3613C16.4105 34.4964 16.3 34.6059 16.1636 34.6059C16.0273 34.6059 15.9168 34.4964 15.9168 34.3613V30.773Z" fill="#5B5869" />
    <path d="M17.0688 30.773C17.0688 30.6379 17.1793 30.5283 17.3156 30.5283C17.4519 30.5283 17.5625 30.6379 17.5625 30.773V34.3613C17.5625 34.4964 17.4519 34.6059 17.3156 34.6059C17.1793 34.6059 17.0688 34.4964 17.0688 34.3613V30.773Z" fill="#5B5869" />
    <path d="M18.2207 30.773C18.2207 30.6379 18.3313 30.5283 18.4676 30.5283C18.6039 30.5283 18.7144 30.6379 18.7144 30.773V34.3613C18.7144 34.4964 18.6039 34.6059 18.4676 34.6059C18.3313 34.6059 18.2207 34.4964 18.2207 34.3613V30.773Z" fill="#5B5869" />
    <path d="M19.3727 30.773C19.3727 30.6379 19.4832 30.5283 19.6196 30.5283C19.7559 30.5283 19.8664 30.6379 19.8664 30.773V34.3613C19.8664 34.4964 19.7559 34.6059 19.6196 34.6059C19.4832 34.6059 19.3727 34.4964 19.3727 34.3613V30.773Z" fill="#5B5869" />
    <path d="M20.5247 30.773C20.5247 30.6379 20.6352 30.5283 20.7715 30.5283C20.9079 30.5283 21.0184 30.6379 21.0184 30.773V34.3613C21.0184 34.4964 20.9079 34.6059 20.7715 34.6059C20.6352 34.6059 20.5247 34.4964 20.5247 34.3613V30.773Z" fill="#5B5869" />
    <path d="M21.6767 30.773C21.6767 30.6379 21.7872 30.5283 21.9235 30.5283C22.0599 30.5283 22.1704 30.6379 22.1704 30.773V34.3613C22.1704 34.4964 22.0599 34.6059 21.9235 34.6059C21.7872 34.6059 21.6767 34.4964 21.6767 34.3613V30.773Z" fill="#5B5869" />
    <path d="M22.8286 30.773C22.8286 30.6379 22.9392 30.5283 23.0755 30.5283C23.2118 30.5283 23.3224 30.6379 23.3224 30.773V34.3613C23.3224 34.4964 23.2118 34.6059 23.0755 34.6059C22.9392 34.6059 22.8286 34.4964 22.8286 34.3613V30.773Z" fill="#5B5869" />
    <path d="M23.9806 30.773C23.9806 30.6379 24.0911 30.5283 24.2275 30.5283C24.3638 30.5283 24.4743 30.6379 24.4743 30.773V34.3613C24.4743 34.4964 24.3638 34.6059 24.2275 34.6059C24.0911 34.6059 23.9806 34.4964 23.9806 34.3613V30.773Z" fill="#5B5869" />
    <path d="M25.1326 30.773C25.1326 30.6379 25.2431 30.5283 25.3795 30.5283C25.5158 30.5283 25.6263 30.6379 25.6263 30.773V34.3613C25.6263 34.4964 25.5158 34.6059 25.3795 34.6059C25.2431 34.6059 25.1326 34.4964 25.1326 34.3613V30.773Z" fill="#5B5869" />
    <path d="M14.7648 30.773C14.7648 30.6379 14.8753 30.5283 15.0117 30.5283C15.148 30.5283 15.2585 30.6379 15.2585 30.773V35.4214C15.2585 35.5566 15.148 35.6661 15.0117 35.6661C14.8753 35.6661 14.7648 35.5566 14.7648 35.4214V30.773Z" fill="#5B5869" />
    <path d="M26.2846 30.773C26.2846 30.6379 26.3951 30.5283 26.5314 30.5283C26.6678 30.5283 26.7783 30.6379 26.7783 30.773V35.4214C26.7783 35.5566 26.6678 35.6661 26.5314 35.6661C26.3951 35.6661 26.2846 35.5566 26.2846 35.4214V30.773Z" fill="#5B5869" />
  </svg>
);

export default PrepaidPassManagerIcon;
/* eslint-enable */
