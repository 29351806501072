/* eslint-disable */
import React from 'react';

const AnalyticsIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path d="M11.3168 19.9935L10.2114 19.1484L17.4536 9.79919L24.5101 16.5954L31.204 11.2694L32.0706 12.3522L24.4216 18.4441L17.6039 11.8768L11.3168 19.9935Z" fill="#CED5E0" />
    <path d="M33.5831 33.2334H29.2237C29.1175 33.2334 29.038 33.1541 29.038 33.0485V17.3168C29.038 17.2112 29.1175 17.132 29.2237 17.132H33.5831C33.6892 17.132 33.7688 17.2112 33.7688 17.3168V33.0485C33.7688 33.1453 33.6892 33.2334 33.5831 33.2334Z" fill="#319ECA" />
    <path d="M26.6514 33.2333H22.292C22.1858 33.2333 22.1063 33.1541 22.1063 33.0484V21.9385C22.1063 21.8329 22.1858 21.7537 22.292 21.7537H26.6514C26.7575 21.7537 26.8371 21.8329 26.8371 21.9385V33.0484C26.8283 33.1453 26.7487 33.2333 26.6514 33.2333Z" fill="#319ECA" />
    <path d="M19.7088 33.2335H15.3494C15.2433 33.2335 15.1637 33.1543 15.1637 33.0487V16.3134C15.1637 16.2078 15.2433 16.1285 15.3494 16.1285H19.7088C19.815 16.1285 19.8945 16.2078 19.8945 16.3134V33.0487C19.8945 33.1455 19.815 33.2335 19.7088 33.2335Z" fill="#319ECA" />
    <path d="M12.7762 33.2336H8.41671C8.3106 33.2336 8.23102 33.1544 8.23102 33.0488V24.8C8.23102 24.6943 8.3106 24.6151 8.41671 24.6151H12.7762C12.8823 24.6151 12.9619 24.6943 12.9619 24.8V33.0488C12.9619 33.1456 12.8734 33.2336 12.7762 33.2336Z" fill="#319ECA" />
    <path d="M36.8461 32.4946H5.15387V34H36.8461V32.4946Z" fill="#CED5E0" />
    <path d="M31.6367 14.4038C33.0725 14.4038 34.2365 13.245 34.2365 11.8156C34.2365 10.3862 33.0725 9.22742 31.6367 9.22742C30.2009 9.22742 29.037 10.3862 29.037 11.8156C29.037 13.245 30.2009 14.4038 31.6367 14.4038Z" fill="#E6E9EE" />
    <path d="M31.6372 13.3208C32.4723 13.3208 33.1493 12.6468 33.1493 11.8154C33.1493 10.984 32.4723 10.3101 31.6372 10.3101C30.8021 10.3101 30.1251 10.984 30.1251 11.8154C30.1251 12.6468 30.8021 13.3208 31.6372 13.3208Z" fill="#9575CD" />
    <path d="M24.4663 19.853C25.9021 19.853 27.0661 18.6943 27.0661 17.2648C27.0661 15.8354 25.9021 14.6766 24.4663 14.6766C23.0305 14.6766 21.8666 15.8354 21.8666 17.2648C21.8666 18.6943 23.0305 19.853 24.4663 19.853Z" fill="#E6E9EE" />
    <path d="M24.4668 18.7704C25.3019 18.7704 25.9789 18.0964 25.9789 17.265C25.9789 16.4336 25.3019 15.7596 24.4668 15.7596C23.6317 15.7596 22.9547 16.4336 22.9547 17.265C22.9547 18.0964 23.6317 18.7704 24.4668 18.7704Z" fill="#9575CD" />
    <path d="M17.5337 13.4264C18.9695 13.4264 20.1334 12.2676 20.1334 10.8382C20.1334 9.40878 18.9695 8.25 17.5337 8.25C16.0978 8.25 14.9339 9.40878 14.9339 10.8382C14.9339 12.2676 16.0978 13.4264 17.5337 13.4264Z" fill="#E6E9EE" />
    <path d="M17.5332 12.3436C18.3683 12.3436 19.0453 11.6697 19.0453 10.8383C19.0453 10.0069 18.3683 9.33289 17.5332 9.33289C16.6981 9.33289 16.0211 10.0069 16.0211 10.8383C16.0211 11.6697 16.6981 12.3436 17.5332 12.3436Z" fill="#9575CD" />
    <path d="M10.5922 22.1596C12.028 22.1596 13.1919 21.0008 13.1919 19.5714C13.1919 18.1419 12.028 16.9832 10.5922 16.9832C9.15638 16.9832 7.99243 18.1419 7.99243 19.5714C7.99243 21.0008 9.15638 22.1596 10.5922 22.1596Z" fill="#E6E9EE" />
    <path d="M10.5916 21.0769C11.4267 21.0769 12.1037 20.4029 12.1037 19.5715C12.1037 18.7401 11.4267 18.0662 10.5916 18.0662C9.75652 18.0662 9.07953 18.7401 9.07953 19.5715C9.07953 20.4029 9.75652 21.0769 10.5916 21.0769Z" fill="#9575CD" />
  </svg>

);

export default AnalyticsIcon;
/* eslint-enable */
