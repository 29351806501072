import React from 'react';

const ExitLeftIcon = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.41 8.41L9 7l-5 5 5 5 1.41-1.41L7.83 13h9.67v-2H7.83l2.58-2.59zM19 3H5a2 2 0 0 0-2 2v5l2-2V5h14v14H5v-3l-2-2v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z"
      fill="currentColor"
    />
  </svg>

);

export default ExitLeftIcon;
