/* eslint-disable */
import React from 'react';

const EducationIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path d="M12.5855 7H30.0315L33.9903 26.2203L32.5404 26.2286L32.5057 33.0145L34 33.0228L33.9902 34.1568H10.407C8.17498 33.9663 8.37407 29.6027 8.2547 28.4347L10.2417 8.89628C10.2493 8.02801 11.0708 7 12.5855 7Z" fill="#20D0C2" />
    <path d="M12.5857 7H14.2565L12.7357 34.1568L10.1279 34.0768C7.94712 33.9855 7.6214 28.429 8.34935 26.4145L10.2419 8.8962C10.2496 8.02801 11.071 7 12.5857 7Z" fill="#FF5B61" />
    <path d="M10.7108 26.2182H33.5383C32.5024 28.5459 32.5293 30.8415 33.5383 33.1065H10.7108C8.26215 33.1066 8.26249 26.2182 10.7108 26.2182Z" fill="white" />
    <path d="M9.05127 28.1119H32.9276C32.9136 28.1808 32.9008 28.2498 32.8887 28.3186H9.0054C9.01968 28.2489 9.03473 28.1797 9.05127 28.1119ZM32.8158 28.8365C32.8083 28.9054 32.8019 28.9744 32.7964 29.0432H8.90127C8.90715 28.9739 8.91453 28.9049 8.92274 28.8365H32.8158ZM32.7725 29.5611C32.7715 29.6301 32.7715 29.6989 32.7725 29.7679H8.8759C8.87494 29.6989 8.87494 29.6301 8.8759 29.5611H32.7725ZM32.7968 30.2856C32.8024 30.3548 32.8088 30.4236 32.8162 30.4924H8.92308C8.91494 30.4239 8.90776 30.3549 8.90181 30.2856H32.7968ZM32.8891 31.0103C32.901 31.0791 32.914 31.1481 32.928 31.217H9.05223C9.03541 31.1489 9.02037 31.08 9.00615 31.0103H32.8891ZM33.0493 31.7347C33.0677 31.8036 33.0871 31.8726 33.1075 31.9414H9.29365C9.26438 31.8747 9.23635 31.8055 9.21023 31.7347H33.0493ZM33.2773 32.4593C33.3024 32.5283 33.3283 32.5973 33.355 32.6661H9.75378C9.69416 32.603 9.63762 32.5337 9.58395 32.4593H33.2773Z" fill="#ECF0F1" />
    <path d="M11.7391 29.7827H15.3063V37L13.5079 34.6455L11.7391 37V29.7827Z" fill="#FF5B61" />
    <path d="M13.0995 27.6624H31.485H32.5333H33.0303C33.154 27.1838 33.3223 26.7043 33.5363 26.223C33.5369 26.2214 33.5377 26.2197 33.5385 26.2182H31.9069H13.1805H10.7109C8.44511 26.2182 8.27583 32.117 10.2037 32.9973C9.55087 30.9657 10.0724 27.6624 11.7679 27.6624H13.0995Z" fill="#DFE1E3" />
    <path d="M19.6815 22.6293H26.5582V22.1037H19.6815V22.6293Z" fill="#ECF0F1" />
    <path d="M20.2349 23.6233H26.0047V23.2297H20.2349V23.6233Z" fill="#ECF0F1" />
  </svg>

);

export default EducationIcon;
/* eslint-enable */
