/* eslint-disable */
import React from 'react';

const LegacyPortalIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path d="M21.5599 27.8086H16.3331V30.5465H21.5599V27.8086Z" fill="#445EA0" />
    <path d="M17.6507 27.8086H16.3331V30.5465H17.6507V27.8086Z" fill="#2E4C89" />
    <path d="M25.0412 30.4803H12.4708C12.2844 30.4803 12.132 30.6328 12.132 30.8192V32.5172C12.132 32.7036 12.2845 32.856 12.4708 32.856H25.0412V30.4803Z" fill="#293D7C" />
    <path d="M13.4497 32.5171V30.819C13.4497 30.6327 13.6021 30.4802 13.7885 30.4802H12.4708C12.2844 30.4802 12.132 30.6327 12.132 30.819V32.5171C12.132 32.7034 12.2845 32.8559 12.4708 32.8559H13.7884C13.6021 32.8559 13.4497 32.7034 13.4497 32.5171Z" fill="#1A2B63" />
    <path d="M33.5544 7.91553H4.3388C4.15247 7.91553 4 8.068 4 8.25433V27.5363C4 27.7227 4.15247 27.8751 4.3388 27.8751H25.0412V17.7306C25.0412 17.4744 25.2497 17.2658 25.5061 17.2658H33.8932V8.25433C33.8932 8.068 33.7407 7.91553 33.5544 7.91553Z" fill="#293D7C" />
    <path d="M5.48724 24.4873C5.48724 24.5991 5.57869 24.6905 5.69051 24.6905H25.0412V17.7307C25.0412 17.4744 25.2497 17.2659 25.5061 17.2659H32.4059V11.2382H5.48724V24.4873Z" fill="#FFFFFF" />
    <path d="M6.80494 24.4873V11.2382H5.48724V24.4873C5.48724 24.5991 5.57869 24.6905 5.69051 24.6905H7.00821C6.89638 24.6905 6.80494 24.599 6.80494 24.4873Z" fill="#D9EAFC" />
    <path d="M18.9466 27.1913C19.4518 27.1913 19.8613 26.7818 19.8613 26.2766C19.8613 25.7715 19.4518 25.3619 18.9466 25.3619C18.4414 25.3619 18.0319 25.7715 18.0319 26.2766C18.0319 26.7818 18.4414 27.1913 18.9466 27.1913Z" fill="#445EA0" />
    <path d="M25.0412 18.8182H21.4935C21.3839 18.8182 21.2943 18.9079 21.2943 19.0175V22.9779C21.2943 23.0874 21.3839 23.1771 21.4935 23.1771H25.0412V18.8182Z" fill="#C3DDF4" />
    <path d="M22.6119 22.9779V19.0175C22.6119 18.9079 22.7015 18.8182 22.8111 18.8182H21.4935C21.3839 18.8182 21.2943 18.9079 21.2943 19.0175V22.9779C21.2943 23.0874 21.3839 23.1771 21.4935 23.1771H22.8111C22.7015 23.1771 22.6119 23.0874 22.6119 22.9779Z" fill="#AEC1ED" />
    <path d="M25.506 17.2658H28.6017V12.9241C28.6017 12.7415 28.4523 12.592 28.2697 12.592H9.62335C9.44073 12.592 9.29132 12.7415 9.29132 12.9241V17.3417C9.29132 17.5244 9.44073 17.6738 9.62335 17.6738H25.0451C25.0732 17.4443 25.2691 17.2658 25.506 17.2658Z" fill="#C3DDF4" />
    <path d="M10.609 17.3416V12.924C10.609 12.7413 10.7584 12.5919 10.941 12.5919H9.62335C9.44074 12.5919 9.29132 12.7413 9.29132 12.924V17.3416C9.29132 17.5242 9.44074 17.6737 9.62335 17.6737H10.941C10.7584 17.6737 10.609 17.5242 10.609 17.3416Z" fill="#AEC1ED" />
    <path d="M32.2027 9.64343H5.69051C5.57869 9.64343 5.48724 9.73487 5.48724 9.84677V11.3046H32.4059V9.84677C32.4059 9.73487 32.3144 9.64343 32.2027 9.64343Z" fill="#FFAF10" />
    <path d="M7.00821 9.64343H5.69051C5.57869 9.64343 5.48724 9.73487 5.48724 9.84677V11.3046H6.80488V9.84677C6.80494 9.73487 6.89638 9.64343 7.00821 9.64343Z" fill="#FF9518" />
    <path d="M15.3889 19.8698H9.79944C9.52438 19.8698 9.30139 19.6468 9.30139 19.3717C9.30139 19.0966 9.52438 18.8737 9.79944 18.8737H15.3889C15.6639 18.8737 15.8869 19.0966 15.8869 19.3717C15.8869 19.6468 15.6639 19.8698 15.3889 19.8698Z" fill="#5DC1D8" />
    <path d="M15.3889 21.4958H9.79944C9.52438 21.4958 9.30139 21.2729 9.30139 20.9978C9.30139 20.7227 9.52438 20.4998 9.79944 20.4998H15.3889C15.6639 20.4998 15.8869 20.7227 15.8869 20.9978C15.8869 21.2729 15.6639 21.4958 15.3889 21.4958Z" fill="#5DC1D8" />
    <path d="M15.3889 23.1219H9.79944C9.52438 23.1219 9.30139 22.899 9.30139 22.6239C9.30139 22.3488 9.52438 22.1259 9.79944 22.1259H15.3889C15.6639 22.1259 15.8869 22.3488 15.8869 22.6239C15.8869 22.899 15.6639 23.1219 15.3889 23.1219Z" fill="#5DC1D8" />
    <path d="M19.7754 19.8698H16.8983C16.6232 19.8698 16.4002 19.6468 16.4002 19.3717C16.4002 19.0966 16.6232 18.8737 16.8983 18.8737H19.7754C20.0504 18.8737 20.2734 19.0966 20.2734 19.3717C20.2734 19.6468 20.0504 19.8698 19.7754 19.8698Z" fill="#5DC1D8" />
    <path d="M19.7754 21.4958H16.8983C16.6232 21.4958 16.4002 21.2729 16.4002 20.9978C16.4002 20.7227 16.6232 20.4998 16.8983 20.4998H19.7754C20.0504 20.4998 20.2734 20.7227 20.2734 20.9978C20.2734 21.2729 20.0504 21.4958 19.7754 21.4958Z" fill="#5DC1D8" />
    <path d="M19.7754 23.1219H16.8983C16.6232 23.1219 16.4002 22.899 16.4002 22.6239C16.4002 22.3488 16.6232 22.1259 16.8983 22.1259H19.7754C20.0504 22.1259 20.2734 22.3488 20.2734 22.6239C20.2734 22.899 20.0504 23.1219 19.7754 23.1219Z" fill="#5DC1D8" />
    <path d="M37.4687 17.1993C37.7609 17.1993 38 17.4384 38 17.7306V33.7856C38 34.0778 37.7609 34.3169 37.4687 34.3169H25.506C25.2139 34.3169 24.9748 34.0778 24.9748 33.7856V17.7306C24.9748 17.4384 25.2139 17.1993 25.506 17.1993H37.4687Z" fill="#445EA0" />
    <path d="M26.4166 32.231C26.234 32.231 26.0846 32.0816 26.0846 31.8989V18.5549C26.0846 18.3723 26.234 18.2229 26.4166 18.2229H36.5584C36.741 18.2229 36.8904 18.3723 36.8904 18.5549V31.8989C36.8904 32.0816 36.741 32.231 36.5584 32.231H26.4166Z" fill="#FFFFFF" />
    <path d="M27.4022 31.8989V18.5549C27.4022 18.3723 27.5516 18.2229 27.7343 18.2229H26.4166C26.234 18.2229 26.0846 18.3723 26.0846 18.5549V31.8989C26.0846 32.0816 26.234 32.231 26.4166 32.231H27.7343C27.5516 32.231 27.4022 32.0816 27.4022 31.8989Z" fill="#D9EAFC" />
    <path d="M30.8658 33.5591C30.6832 33.5591 30.5338 33.4097 30.5338 33.2271V33.0979C30.5338 32.9153 30.6832 32.7659 30.8658 32.7659H32.1092C32.2918 32.7659 32.4412 32.9153 32.4412 33.0979V33.2271C32.4412 33.4097 32.2918 33.5591 32.1092 33.5591H30.8658Z" fill="#445EA0" />
    <path d="M36.8904 19.5041V18.5549C36.8904 18.3723 36.741 18.2229 36.5584 18.2229H26.4166C26.234 18.2229 26.0846 18.3723 26.0846 18.5549V19.5041H36.8904Z" fill="#FFAF10" />
    <path d="M27.7343 18.2229H26.4166C26.234 18.2229 26.0846 18.3723 26.0846 18.5549V19.5041H27.4022V18.5549C27.4022 18.3723 27.5516 18.2229 27.7343 18.2229Z" fill="#FF9518" />
    <path d="M33.7508 25.5776H29.2239C28.9489 25.5776 28.7259 25.3547 28.7259 25.0796C28.7259 24.8045 28.9489 24.5815 29.2239 24.5815H33.7508C34.0258 24.5815 34.2488 24.8045 34.2488 25.0796C34.2488 25.3546 34.0258 25.5776 33.7508 25.5776Z" fill="#5DC1D8" />
    <path d="M33.7508 27.1134H29.2239C28.9489 27.1134 28.7259 26.8905 28.7259 26.6154C28.7259 26.3403 28.9489 26.1173 29.2239 26.1173H33.7508C34.0258 26.1173 34.2488 26.3403 34.2488 26.6154C34.2488 26.8905 34.0258 27.1134 33.7508 27.1134Z" fill="#5DC1D8" />
    <path d="M33.7508 28.649H29.2239C28.9489 28.649 28.7259 28.4261 28.7259 28.151C28.7259 27.8759 28.9489 27.653 29.2239 27.653H33.7508C34.0258 27.653 34.2488 27.8759 34.2488 28.151C34.2488 28.4261 34.0258 28.649 33.7508 28.649Z" fill="#5DC1D8" />
    <path d="M35.8587 23.4077C35.8587 23.5903 35.7093 23.7397 35.5267 23.7397H27.4481C27.2654 23.7397 27.116 23.5903 27.116 23.4077V20.6688C27.116 20.4862 27.2654 20.3368 27.4481 20.3368H35.5267C35.7093 20.3368 35.8587 20.4862 35.8587 20.6688V23.4077Z" fill="#C3DDF4" />
    <path d="M28.4337 23.4077V20.6688C28.4337 20.4862 28.5831 20.3368 28.7657 20.3368H27.4481C27.2654 20.3368 27.116 20.4862 27.116 20.6688V23.4077C27.116 23.5903 27.2654 23.7397 27.4481 23.7397H28.7657C28.5831 23.7397 28.4337 23.5903 28.4337 23.4077Z" fill="#AEC1ED" />
    <path d="M34.6635 31.1877C34.6635 31.3704 34.5141 31.5198 34.3315 31.5198H28.6433C28.4607 31.5198 28.3113 31.3704 28.3113 31.1877V30.0648C28.3113 29.8822 28.4607 29.7328 28.6433 29.7328H34.3315C34.5141 29.7328 34.6635 29.8822 34.6635 30.0648V31.1877Z" fill="#C3DDF4" />
    <path d="M29.6289 31.1877V30.0648C29.6289 29.8822 29.7783 29.7328 29.9609 29.7328H28.6433C28.4607 29.7328 28.3113 29.8822 28.3113 30.0648V31.1877C28.3113 31.3704 28.4607 31.5198 28.6433 31.5198H29.9609C29.7783 31.5198 29.6289 31.3704 29.6289 31.1877Z" fill="#AEC1ED" />
  </svg>
);

export default LegacyPortalIcon;
/* eslint-enable */
