/* eslint-disable */
import React from 'react';

const DevicesIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <ellipse cx="21.1697" cy="33.988" rx="12.0242" ry="1.01204" fill="black" fillOpacity="0.2" />
    <path d="M13.5002 8.78838C13.5002 8.47197 13.5963 8.17836 13.7575 7.93082L9.05876 9.18063C8.20705 9.40724 7.70174 10.276 7.93002 11.1214L13.4659 31.6298C13.4711 31.6494 13.5002 31.6457 13.5002 31.6252V8.78838Z" fill="#869EA2" />
    <path d="M28.5001 8.32326C28.5001 8.00685 28.404 7.71324 28.2427 7.4657L32.9415 8.71551C33.7932 8.94212 34.2985 9.81085 34.0702 10.6563L28.5343 31.1647C28.5291 31.1843 28.5001 31.1805 28.5001 31.1601V8.32326Z" fill="#869EA2" />
    <path d="M26.9048 33.988H15.0975C14.2158 33.988 13.5009 33.2784 13.5009 32.4036V8.58455C13.5009 7.70978 14.2158 7.00018 15.0975 7.00018H26.9048C27.7865 7.00018 28.5009 7.70978 28.5009 8.58455V32.4032C28.5009 33.2784 27.7865 33.988 26.9048 33.988Z" fill="#424A60" />
    <path d="M28.5009 9.79196H13.5009V28.4042H28.5009V9.79196Z" fill="white" />
    <rect x="14.298" y="13.7202" width="13.4042" height="2.79999" fill="#38CFFF" />
    <rect x="14.298" y="17.3601" width="13.4042" height="2.79999" fill="#5AE5A9" />
    <rect x="14.298" y="21.0001" width="13.4042" height="2.79999" fill="#F2D90F" />
    <rect x="14.298" y="24.6401" width="13.4042" height="2.79999" fill="#FD4D30" />
    <path d="M21.0004 32.592C21.777 32.592 22.4066 31.967 22.4066 31.196C22.4066 30.4251 21.777 29.8001 21.0004 29.8001C20.2237 29.8001 19.5941 30.4251 19.5941 31.196C19.5941 31.967 20.2237 32.592 21.0004 32.592Z" fill="#262B35" />
    <path d="M21 8.86143H19.125C18.8658 8.86143 18.6562 8.65344 18.6562 8.39612C18.6562 8.13881 18.8658 7.93082 19.125 7.93082H21C21.2592 7.93082 21.4687 8.13881 21.4687 8.39612C21.4687 8.65344 21.2592 8.86143 21 8.86143Z" fill="#262B35" />
    <path d="M22.8749 8.86143H22.4061C22.1469 8.86143 21.9374 8.65344 21.9374 8.39612C21.9374 8.13881 22.1469 7.93082 22.4061 7.93082H22.8749C23.1341 7.93082 23.3436 8.13881 23.3436 8.39612C23.3436 8.65344 23.1341 8.86143 22.8749 8.86143Z" fill="#262B35" />
  </svg>

);

export default DevicesIcon;
/* eslint-enable */
