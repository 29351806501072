/* eslint-disable */
import React from 'react';

const LiveIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path d="M13.2725 24.816C15.2468 24.816 16.8474 26.4242 16.8474 28.4079C16.8474 30.3916 15.2468 31.9998 13.2725 31.9998C11.2981 31.9998 9.69751 30.3916 9.69751 28.4079C9.69751 26.4242 11.2981 24.816 13.2725 24.816Z" fill="#1B2A38" />
    <path d="M28.7646 24.8163C30.739 24.8163 32.3395 26.4244 32.3395 28.4081C32.3395 30.3919 30.739 32 28.7646 32C26.7902 32 25.1896 30.3919 25.1896 28.4081C25.1896 26.4244 26.7902 24.8163 28.7646 24.8163Z" fill="#1B2A38" />
    <path d="M34.424 26.6871C33.3068 29.0816 30.7373 30.5408 28.056 30.1667C27.6837 30.1293 27.3485 30.0171 27.0878 29.9048C26.2314 29.4933 24.4811 29.1565 23.066 28.932C23.066 28.932 23.066 28.932 23.066 28.8946C23.066 27.7347 22.1351 26.8367 21.0178 26.8367C19.8634 26.8367 18.9696 27.7721 18.9696 28.8946C18.9696 28.8946 18.9696 28.8946 18.9696 28.932C17.5545 29.1565 15.8043 29.4933 14.9478 29.9048C14.6871 30.0171 14.352 30.1293 13.9796 30.1667C11.2984 30.5408 8.7289 29.0443 7.61167 26.6871L4.48358 20.0271L21.0178 23.17L37.5521 20.0271L34.424 26.6871Z" fill="#3F4B5B" />
    <path d="M29.6204 15.0512C24.109 17.3336 17.89 17.3336 12.3786 15.0512C11.5593 17.0717 10.7028 19.092 9.88355 21.1499C16.9963 24.1058 25.0027 24.1058 32.1153 21.1499C31.2962 19.1295 30.4769 17.109 29.6204 15.0512Z" fill="#1B2A38" />
    <path d="M11.3734 9.99991C15.4456 9.99991 18.7468 13.3167 18.7468 17.4082C18.7468 21.4997 15.4456 24.8165 11.3734 24.8165C7.30119 24.8165 4 21.4997 4 17.4082C4 13.3167 7.30119 9.99991 11.3734 9.99991Z" fill="#2B3B4E" />
    <path d="M11.3733 11.7207C14.4994 11.7207 17.0337 14.267 17.0337 17.4079C17.0337 20.5489 14.4994 23.0951 11.3733 23.0951C8.24714 23.0951 5.71289 20.5489 5.71289 17.4079C5.71289 14.267 8.24714 11.7207 11.3733 11.7207Z" fill="#54C0EB" />
    <path d="M11.3733 13.2923C13.6356 13.2923 15.4696 15.135 15.4696 17.408C15.4696 19.681 13.6356 21.5237 11.3733 21.5237C9.11096 21.5237 7.27698 19.681 7.27698 17.408C7.27698 15.135 9.11096 13.2923 11.3733 13.2923Z" fill="#84DBFF" />
    <path d="M11.3734 16.6595C11.7847 16.6595 12.1182 16.9945 12.1182 17.4078C12.1182 17.8211 11.7847 18.1561 11.3734 18.1561C10.9621 18.1561 10.6286 17.8211 10.6286 17.4078C10.6286 16.9945 10.9621 16.6595 11.3734 16.6595Z" fill="white" />
    <path d="M8.39468 18.493C8.99114 18.493 9.47466 18.9788 9.47466 19.5781C9.47466 20.1774 8.99114 20.6632 8.39468 20.6632C7.79822 20.6632 7.3147 20.1774 7.3147 19.5781C7.3147 18.9788 7.79822 18.493 8.39468 18.493Z" fill="white" />
    <path d="M30.6266 9.99991C34.6988 9.99991 38 13.3167 38 17.4082C38 21.4997 34.6988 24.8165 30.6266 24.8165C26.5544 24.8165 23.2532 21.4997 23.2532 17.4082C23.2532 13.3167 26.5544 9.99991 30.6266 9.99991Z" fill="#2B3B4E" />
    <path d="M30.6257 11.7207C33.7518 11.7207 36.2861 14.267 36.2861 17.4079C36.2861 20.5489 33.7518 23.0951 30.6257 23.0951C27.4995 23.0951 24.9653 20.5489 24.9653 17.4079C24.9653 14.267 27.4995 11.7207 30.6257 11.7207Z" fill="#54C0EB" />
    <path d="M30.6265 13.2923C32.8888 13.2923 34.7228 15.135 34.7228 17.408C34.7228 19.681 32.8888 21.5237 30.6265 21.5237C28.3641 21.5237 26.5302 19.681 26.5302 17.408C26.5302 15.135 28.3641 13.2923 30.6265 13.2923Z" fill="#84DBFF" />
    <path d="M30.6261 16.6595C31.0375 16.6595 31.3709 16.9945 31.3709 17.4078C31.3709 17.8211 31.0375 18.1561 30.6261 18.1561C30.2148 18.1561 29.8813 17.8211 29.8813 17.4078C29.8813 16.9945 30.2148 16.6595 30.6261 16.6595Z" fill="white" />
    <path d="M27.6466 18.493C28.2431 18.493 28.7266 18.9788 28.7266 19.5781C28.7266 20.1774 28.2431 20.6632 27.6466 20.6632C27.0502 20.6632 26.5667 20.1774 26.5667 19.5781C26.5667 18.9788 27.0502 18.493 27.6466 18.493Z" fill="white" />
    <path d="M21.0178 18.1563C22.1695 18.1563 23.1032 19.0944 23.1032 20.2516C23.1032 21.4087 22.1695 22.3468 21.0178 22.3468C19.8661 22.3468 18.9324 21.4087 18.9324 20.2516C18.9324 19.0944 19.8661 18.1563 21.0178 18.1563Z" fill="#324A5E" />
    <path d="M21.0182 18.7927C21.8203 18.7927 22.4705 19.446 22.4705 20.2519C22.4705 21.0578 21.8203 21.7111 21.0182 21.7111C20.2161 21.7111 19.5659 21.0578 19.5659 20.2519C19.5659 19.446 20.2161 18.7927 21.0182 18.7927Z" fill="#2B3B4E" />
    <path d="M23.9606 27.9968C23.2531 25.9389 21.0187 25.8641 21.0187 25.8641C21.0187 25.8641 18.7471 25.939 18.0768 27.9968L17.0341 28.1838V24.143H21.0187H25.0033V28.1838L23.9606 27.9968Z" fill="#1B2A38" />
  </svg>
);

export default LiveIcon;
/* eslint-enable */
