/* eslint-disable */
import React from 'react';

const VipsIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3964 2.5658C17.6338 2.5658 17.8262 2.75821 17.8262 2.99556C17.8262 5.24925 17.9228 7.72854 18.387 9.63498C18.6194 10.5895 18.9352 11.3612 19.3464 11.8862C19.7456 12.3959 20.2253 12.6652 20.8345 12.6652C21.0719 12.6652 21.2643 12.8576 21.2643 13.095C21.2643 13.3323 21.0719 13.5247 20.8345 13.5247C19.9157 13.5247 19.2017 13.0954 18.6697 12.4162C18.1498 11.7523 17.797 10.8449 17.5519 9.83833C17.0611 7.82268 16.9667 5.25227 16.9667 2.99556C16.9667 2.75821 17.1591 2.5658 17.3964 2.5658Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24.2726 2.5658C24.0353 2.5658 23.8429 2.75821 23.8429 2.99556C23.8429 5.24925 23.7463 7.72854 23.2821 9.63498C23.0496 10.5895 22.7339 11.3612 22.3227 11.8862C21.9235 12.3959 21.4438 12.6652 20.8345 12.6652C20.5972 12.6652 20.4048 12.8576 20.4048 13.095C20.4048 13.3323 20.5972 13.5247 20.8345 13.5247C21.7533 13.5247 22.4674 13.0954 22.9994 12.4162C23.5193 11.7523 23.8721 10.8449 24.1172 9.83833C24.608 7.82268 24.7024 5.25227 24.7024 2.99556C24.7024 2.75821 24.51 2.5658 24.2726 2.5658Z" fill="#EDEDED" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3881 11.8057C13.4387 11.8057 12.6691 12.5754 12.6691 13.5248V30.7152C12.6691 31.6646 13.4387 32.4343 14.3881 32.4343H27.281C28.2304 32.4343 29 31.6646 29 30.7152V13.5248C29 12.5754 28.2304 11.8057 27.281 11.8057H14.3881ZM20.8345 14.3843C21.3092 14.3843 21.6941 13.9995 21.6941 13.5248C21.6941 13.0501 21.3092 12.6652 20.8345 12.6652C20.3598 12.6652 19.975 13.0501 19.975 13.5248C19.975 13.9995 20.3598 14.3843 20.8345 14.3843Z" fill="#D9A9FF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3964 2.5658C17.6338 2.5658 17.8262 2.75821 17.8262 2.99556C17.8262 5.24925 17.9228 7.72854 18.387 9.63498C18.6194 10.5895 18.9352 11.3612 19.3464 11.8862C19.7456 12.3959 20.2253 12.6652 20.8345 12.6652C21.0719 12.6652 21.2643 12.8576 21.2643 13.095C21.2643 13.3323 21.0719 13.5247 20.8345 13.5247C19.9157 13.5247 19.2017 13.0954 18.6697 12.4162C18.1498 11.7523 17.797 10.8449 17.5519 9.83833C17.0611 7.82268 16.9667 5.25227 16.9667 2.99556C16.9667 2.75821 17.1591 2.5658 17.3964 2.5658Z" fill="#EDEDED" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.9419 15.8884C21.1281 15.8884 21.2931 16.0083 21.3507 16.1854L22.2432 18.9322H25.1314C25.3176 18.9322 25.4826 19.0521 25.5401 19.2292C25.5977 19.4063 25.5346 19.6003 25.384 19.7097L23.0474 21.4073L23.9399 24.1542C23.9974 24.3313 23.9344 24.5253 23.7838 24.6347C23.6331 24.7441 23.4292 24.7441 23.2786 24.6347L20.9419 22.937L18.6053 24.6347C18.4547 24.7441 18.2507 24.7441 18.1001 24.6347C17.9495 24.5253 17.8865 24.3313 17.944 24.1542L18.8365 21.4073L16.4999 19.7097C16.3493 19.6003 16.2862 19.4063 16.3438 19.2292C16.4013 19.0521 16.5663 18.9322 16.7525 18.9322H19.6407L20.5332 16.1854C20.5907 16.0083 20.7558 15.8884 20.9419 15.8884ZM20.9419 17.7089L20.3617 19.4948C20.3041 19.6719 20.1391 19.7918 19.9529 19.7918H18.0752L19.5943 20.8955C19.7449 21.0049 19.808 21.1989 19.7504 21.376L19.1702 23.1619L20.6893 22.0581C20.84 21.9487 21.0439 21.9487 21.1945 22.0581L22.7137 23.1619L22.1334 21.376C22.0759 21.1989 22.1389 21.0049 22.2896 20.8955L23.8087 19.7918H21.9309C21.7448 19.7918 21.5797 19.6719 21.5222 19.4948L20.9419 17.7089Z" fill="#FFED5C" />
    <path d="M14.3881 27.2771C14.3881 27.0397 14.5805 26.8473 14.8179 26.8473H20.6197C20.857 26.8473 21.0494 27.0397 21.0494 27.2771V27.7068C21.0494 27.9442 20.857 28.1366 20.6197 28.1366H14.8179C14.5805 28.1366 14.3881 27.9442 14.3881 27.7068V27.2771Z" fill="white" fillOpacity="0.4" />
    <path d="M14.3881 29.3184C14.3881 29.1404 14.5324 28.9961 14.7104 28.9961H18.1485C18.3265 28.9961 18.4709 29.1404 18.4709 29.3184C18.4709 29.4964 18.3265 29.6407 18.1485 29.6407H14.7104C14.5324 29.6407 14.3881 29.4964 14.3881 29.3184Z" fill="white" fillOpacity="0.4" />
    <path d="M14.3881 30.3928C14.3881 30.2148 14.5324 30.0705 14.7104 30.0705H20.2973C20.4754 30.0705 20.6197 30.2148 20.6197 30.3928C20.6197 30.5708 20.4754 30.7151 20.2973 30.7151H14.7104C14.5324 30.7151 14.3881 30.5708 14.3881 30.3928Z" fill="white" fillOpacity="0.4" />
  </svg>
);

export default VipsIcon;
/* eslint-enable */
