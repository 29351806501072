/* eslint-disable */
import React from 'react';

const ApiProvisioningIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path d="M31.9992 7H6V26.5078H31.9992V7Z" fill="#E0E0E0" />
    <path d="M32 7H19V26.5078H32V7Z" fill="#C0C0C0" />
    <path d="M31.9992 7H6V9.51027H31.9992V7Z" fill="#C0C0C0" />
    <path d="M32 7H19V9.51027H32V7Z" fill="#929292" />
    <path d="M7.56623 8.95069C7.95502 8.95069 8.27019 8.63917 8.27019 8.25489C8.27019 7.8706 7.95502 7.55908 7.56623 7.55908C7.17745 7.55908 6.86227 7.8706 6.86227 8.25489C6.86227 8.63917 7.17745 8.95069 7.56623 8.95069Z" fill="#FF6838" />
    <path d="M9.67799 8.95069C10.0668 8.95069 10.3819 8.63917 10.3819 8.25489C10.3819 7.8706 10.0668 7.55908 9.67799 7.55908C9.2892 7.55908 8.97403 7.8706 8.97403 8.25489C8.97403 8.63917 9.2892 8.95069 9.67799 8.95069Z" fill="#FF6838" />
    <path d="M11.7898 8.95069C12.1786 8.95069 12.4937 8.63917 12.4937 8.25489C12.4937 7.8706 12.1786 7.55908 11.7898 7.55908C11.401 7.55908 11.0858 7.8706 11.0858 8.25489C11.0858 8.63917 11.401 8.95069 11.7898 8.95069Z" fill="#FF6838" />
    <path d="M7.01245 15.9905L8.9658 10.9692H9.91741L11.8636 15.9905H10.6185L10.2251 14.866H8.64378L8.25742 15.9905H7.01245ZM9.43806 12.1998L8.82986 14.0668H10.0248L9.43806 12.1998Z" fill="#005C83" />
    <path d="M12.3284 15.9905V10.9692H14.4964C14.7397 10.9692 14.9626 11.0187 15.1654 11.1177C15.3681 11.2168 15.5421 11.3464 15.6877 11.5067C15.8332 11.6671 15.9477 11.8486 16.0312 12.0513C16.1147 12.2541 16.1564 12.4592 16.1564 12.6666C16.1564 12.8883 16.117 13.1004 16.0384 13.3031C15.9597 13.5059 15.8499 13.6874 15.7092 13.8477C15.5685 14.008 15.398 14.1353 15.1977 14.2295C14.9973 14.3239 14.7755 14.371 14.5323 14.371H13.5019V15.9905H12.3284V15.9905ZM13.5018 13.3596H14.4607C14.5989 13.3596 14.7183 13.3007 14.8184 13.1828C14.9186 13.0649 14.9686 12.8928 14.9686 12.6665C14.9686 12.5487 14.9531 12.4472 14.9221 12.3624C14.8909 12.2775 14.8493 12.2068 14.7968 12.1502C14.7443 12.0937 14.6847 12.0525 14.6179 12.0265C14.5511 12.0006 14.4844 11.9875 14.4176 11.9875H13.5017V13.3596H13.5018Z" fill="#005C83" />
    <path d="M16.7649 15.9905V10.9692H17.9383V15.9905H16.7649Z" fill="#005C83" />
    <path d="M32.7337 21.6067L33.8202 20.5202C33.199 19.6315 32.4251 18.8576 31.5365 18.2365L30.45 19.323C29.678 18.8226 28.8108 18.4569 27.8815 18.2586V16.7214C27.3572 16.6286 26.8176 16.5801 26.2667 16.5801C25.7158 16.5801 25.1762 16.6286 24.6519 16.7214V18.2586C23.7226 18.4568 22.8554 18.8226 22.0834 19.3229L20.9969 18.2364C20.1082 18.8576 19.3343 19.6314 18.7132 20.5201L19.7997 21.6066C19.2993 22.3785 18.9336 23.2458 18.7352 24.1751H17.198C17.1053 24.6994 17.0568 25.239 17.0568 25.7899C17.0568 26.3408 17.1053 26.8804 17.198 27.4047H18.7352C18.9335 28.334 19.2993 29.2012 19.7997 29.9732L18.7132 31.0597C19.3343 31.9484 20.1082 32.7222 20.9969 33.3434L22.0834 32.2569C22.8553 32.7573 23.7226 33.123 24.6519 33.3213V34.8585C25.1762 34.9513 25.7158 34.9998 26.2667 34.9998C26.8176 34.9998 27.3572 34.9513 27.8815 34.8585V33.3213C28.8108 33.1231 29.6779 32.7573 30.45 32.2569L31.5365 33.3434C32.4251 32.7222 33.199 31.9484 33.8202 31.0597L32.7337 29.9732C33.2341 29.2012 33.5998 28.334 33.7981 27.4047H35.3353C35.428 26.8804 35.4766 26.3408 35.4766 25.7899C35.4766 25.239 35.428 24.6994 35.3353 24.1751H33.7981C33.5998 23.2459 33.2341 22.3787 32.7337 21.6067ZM26.2667 29.1914C24.3881 29.1914 22.8653 27.6685 22.8653 25.79C22.8653 23.9115 24.3881 22.3886 26.2667 22.3886C28.1452 22.3886 29.6681 23.9115 29.6681 25.79C29.6681 27.6685 28.1451 29.1914 26.2667 29.1914Z" fill="#005C83" />
    <path d="M35.3351 27.4049C35.4278 26.8806 35.4764 26.341 35.4764 25.79C35.4764 25.2391 35.4278 24.6995 35.3351 24.1752H33.7979C33.5996 23.2459 33.2338 22.3786 32.7335 21.6067L33.82 20.5202C33.1989 19.6315 32.425 18.8576 31.5363 18.2365L30.4498 19.323C29.6779 18.8226 28.8107 18.4568 27.8814 18.2586V16.7214C27.357 16.6286 26.8174 16.5801 26.2665 16.5801C26.2308 16.5801 26.1955 16.582 26.1599 16.5824V22.3913C26.1954 22.3902 26.2308 22.3886 26.2665 22.3886C28.1451 22.3886 29.6679 23.9114 29.6679 25.7899C29.6679 27.6684 28.1452 29.1913 26.2665 29.1913C26.2308 29.1913 26.1954 29.1897 26.1599 29.1886V34.9975C26.1956 34.9979 26.2309 34.9998 26.2665 34.9998C26.8175 34.9998 27.357 34.9513 27.8814 34.8585V33.3213C28.8107 33.1231 29.6778 32.7573 30.4498 32.2569L31.5363 33.3434C32.425 32.7222 33.1989 31.9484 33.82 31.0596L32.7335 29.9731C33.2339 29.2011 33.5996 28.3339 33.7979 27.4046H35.3351V27.4049Z" fill="#00263F" />
  </svg>
);

export default ApiProvisioningIcon;
/* eslint-enable */
